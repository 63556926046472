import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import * as AOS from 'aos';
import { environment } from 'src/environments/environment';
import {
  AuthService,
  MeetingService,
  ModuleService,
  NotificationService,
  SearchService,
  SidenavService,
  SocketService,
} from './services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private _authServiceInitialized = false;
  host: string = environment.serviceHost;

  showDevBanner = environment.showDevBanner;
  loading = true;

  constructor(
    public authService: AuthService,
    private _moduleService: ModuleService,
    private meetingService: MeetingService,
    private navService: SidenavService,
    public searchService: SearchService,
    private socketService: SocketService,
    public notificationService: NotificationService,
    public titleService: Title,
    router: Router,
    public activatedRoute: ActivatedRoute
  ) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const title = this.getTitle(router.routerState, router.routerState.root).join('-');
        titleService.setTitle(title);
      }
    });

    setTimeout(() => {
      this._authServiceInitialized = true;
    });
  }

  public get isZippingNotes(): boolean {
    return this.meetingService?.isZippingNotes;
  }

  getTitle(state, parent) {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if (state && parent) {
      data.push(...this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }

  ngOnInit() {
    AOS.init();
    this.socketService.getSocket();
    // await this.authService.getProjectScopes();
    this.loading = false;
  }

  logout() {
    this.authService.logout();
  }

  isSidenavClosed(): boolean {
    return this.navService.isSidenavClosed;
  }

  shouldShowNav() {
    return this._authServiceInitialized && this.authService.isLoggedIn;
  }

  nukeTheApi() {
    const callCount = 30;

    for (let i = 0; i < callCount; i++) {
      void this.searchService.search('t').toPromise();
    }
  }
}
